import doFetch from '../../fetch';
const GAMEPATH = '/api/game';

/**
 *  phone verifiction
 * @param data
 */
const actions = {
  created: async <T>(data: object) =>
    await doFetch<T>(`${GAMEPATH}/v1/verification/send_sms_code`, 'POST', data),
  updated: async <T>(data: object) =>
    await doFetch<T>(`${GAMEPATH}/v1/verification/check_sms_code`, 'POST', data),
  read: async <T>(data: object) =>
    await doFetch<T>(`${GAMEPATH}/v1/verification/send_sms_verify`, 'POST', data),
  checkField: async <T>(data: object) =>
    await doFetch<T>(`${GAMEPATH}/v1/verification/check_field_value`, 'POST', data)
};

export const phoneVerification = actions;
